import React from 'react'
import { motion } from "framer-motion"
import { Tooltip } from 'react-tooltip'


import '../../styles/AffiliateCard.scss';

export const AffiliateCard = ({id, title="", desc="", className="", img, url}) => {

  return (
    <motion.div
      id={id}
      className={`${className} affiliate-card`}
      style={{backgroundImage:img}}
    >
         {/* <img src={img} alt={title} loading='lazy' /> */}
         <img 
          alt={title}
          src={img}
          loading='lazy'
         />
         <a
          className='affiliate-link'
          href={url}
          target='_blank'
          rel='noreferrer'>
            <h4>{title}</h4>
            <p>{desc}</p>
         </a>
        <Tooltip id={`${id}-preview`} />
    </motion.div>
  )
}

// export default ProductCard